import * as React from 'react';
import navigate from '../../utils/navigate';

class Redirect extends React.Component {
  componentDidMount() {
    navigate(this.props.to);
  }
  render() {
    return null;
  }
}

export default Redirect;
