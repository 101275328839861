import * as React from "react";
import { Router } from "@reach/router";
import Redirect from "../components/Redirect";
import localize from "../hoc/Localize";

const PageComponent: React.FunctionComponent<{
    coupon?: string;
    path: string;
}> = ({ coupon }) => {
    return (<Redirect to={`/subscribe?coupon=${coupon}`} />);
};

const Promo: React.FunctionComponent = props => (
    <Router>
        <PageComponent path="/promo/:coupon" />
    </Router>
);

export default localize(Promo);
